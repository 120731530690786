import React from 'react'
import { GridItem } from '@which/seatbelt'

import { Breadcrumb } from '../../../../shared/components/Breadcrumb'
import { VerticalHeader } from '../../components/VerticalHeader'
import { VerticalMore } from '../../components/VerticalMore'
import { VerticalNavigation } from '../../components/VerticalNavigation'
import styles from '../../VerticalPage.module.scss'
import navStyles from './PensionScheme.module.scss'

export const getPensionSchemeVerticalComponents = ({ header, pensionScheme }) => ({
  VerticalHeader: () => (
    <GridItem span={{ medium: 8, large: 8 }} columnStart={{ medium: 3, large: 3 }}>
      <VerticalHeader
        description={header.description}
        title={header.title}
        standfirst={header.standfirst}
        size={5}
        className={styles.verticalHeader}
      />
    </GridItem>
  ),
  PensionArticlesNavigation: () => (
    <GridItem span={{ medium: 8, large: 8 }} columnStart={{ medium: 3, large: 3 }}>
      <VerticalNavigation data={pensionScheme} type="articles" />
    </GridItem>
  ),
  PensionWidgetsNavigation: () => (
    <section className={pensionScheme?.promoUrls?.length ? navStyles.awPromoBlockWrapper : ''}>
      <div className="w-page-wrapper">
        <GridItem span={{ medium: 12, large: 12 }}>
          <VerticalNavigation data={pensionScheme} type="widgets" />
        </GridItem>
      </div>
    </section>
  ),
  PensionMore: () => <VerticalMore data={pensionScheme} />,
  Breadcrumb: () => <Breadcrumb heading={{ text: header.title }} currentPage={null} links={[]} />,
})
