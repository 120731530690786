import { useParams } from 'react-router-dom'
import { assertNever } from '@which/shared'

import type { PageParams } from '../../../routes'
import { usePageProps } from '../../../shared/usePageProps'
import type {
  VerticalAboutWhichPageData,
  VerticalCampaignsPageData,
  VerticalCrPageData,
  VerticalMyAppointmentsPageData,
  VerticalNewsFullListingPageData,
  VerticalNewsPageData,
  VerticalPensionSchemePageData,
  VerticalPolicyAndInsightPageData,
  VerticalReviewsPageData,
  VerticalSitemapPageData,
  VerticalTemplateName,
  VerticalVariantTypes,
} from '../vertical-types'
import { getAboutWhichVerticalComponents } from '../vertical-variants/about-which-vertical'
import { getCampaignsVerticalComponents } from '../vertical-variants/campaigns-vertical'
import { getCrVerticalComponents } from '../vertical-variants/cr-vertical'
import { getMyAppointmentsVerticalComponents } from '../vertical-variants/my-appointments-vertical'
import { getNewsFullListingComponents } from '../vertical-variants/news-full-listing'
import { getNewsVerticalComponents } from '../vertical-variants/news-vertical'
import { getPensionSchemeVerticalComponents } from '../vertical-variants/pension-scheme-vertical/index'
import { getPolicyAndInsightVerticalComponents } from '../vertical-variants/policy-and-insight-vertical'
import { getReviewsVerticalComponents } from '../vertical-variants/reviews-vertical'
import { getSitemapVerticalComponents } from '../vertical-variants/sitemap-vertical'
import { getComponents } from './base-components'

export const useVerticalPageComponents = () => {
  const { template, context } = usePageProps()
  const verticalTemplate = template as VerticalTemplateName
  const { verticalSlug } = useParams<PageParams>()

  return {
    getPageComponents(data: VerticalVariantTypes) {
      switch (verticalTemplate) {
        case 'CR Vertical':
          return getComponents(
            data as VerticalCrPageData,
            getCrVerticalComponents(data as VerticalCrPageData)
          )
        case 'Reviews Vertical':
          return getComponents(
            data as VerticalReviewsPageData,
            getReviewsVerticalComponents(data as VerticalReviewsPageData)
          )
        case 'News Vertical':
          return getNewsVerticalComponents(data as VerticalNewsPageData)
        case 'News Full Listing':
          return getNewsFullListingComponents(data as VerticalNewsFullListingPageData, {
            context: context || '',
            verticalSlug,
          })
        case 'Policy and Insight Vertical':
          return getPolicyAndInsightVerticalComponents(data as VerticalPolicyAndInsightPageData)
        case 'Campaigns Vertical':
          return getCampaignsVerticalComponents(data as VerticalCampaignsPageData)
        case 'About Which Vertical':
          return getComponents(
            data as VerticalAboutWhichPageData,
            getAboutWhichVerticalComponents(data as VerticalAboutWhichPageData)
          )
        case 'Sitemap Vertical':
          return getComponents(
            data as VerticalSitemapPageData,
            getSitemapVerticalComponents(data as VerticalSitemapPageData)
          )
        case 'My Appointments Vertical':
          return getMyAppointmentsVerticalComponents(data as VerticalMyAppointmentsPageData)
        case 'Pension Scheme Vertical':
          return getComponents(
            data,
            getPensionSchemeVerticalComponents(data as VerticalPensionSchemePageData)
          )
        default:
          assertNever(verticalTemplate)
      }
    },
  }
}
