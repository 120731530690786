import type { VerticalCrPageQuery } from '../../../generated/frontend'
import type { PageInfo } from '../../../shared/types'
import { usePageProps } from '../../../shared/usePageProps'
import { getAboutWhichVerticalDataLayer } from '../vertical-variants/about-which-vertical/data-layer'
import { getCRVerticalDataLayer } from '../vertical-variants/cr-vertical'
import { getNewsFullListingDataLayer } from '../vertical-variants/news-full-listing'
import { getNewsVerticalDataLayer } from '../vertical-variants/news-vertical'
import { getPensionSchemeVerticalDataLayer } from '../vertical-variants/pension-scheme-vertical/data-layer'

export const useVerticalPageDatalayer = () => {
  const { template } = usePageProps()

  return {
    getPageDatalayer(pageInfo: PageInfo, callout?: VerticalCrPageQuery['verticalPage']['callout']) {
      switch (template) {
        case 'CR Vertical':
          return getCRVerticalDataLayer(pageInfo, callout)
        case 'News Vertical':
          return getNewsVerticalDataLayer(pageInfo)
        case 'News Full Listing':
          return getNewsFullListingDataLayer(pageInfo)
        case 'About Which Vertical':
          return getAboutWhichVerticalDataLayer(pageInfo)
        case 'Pension Scheme Vertical':
          return getPensionSchemeVerticalDataLayer(pageInfo)
        default:
          return pageInfo
      }
    },
  }
}
