import type { FunctionComponent } from 'react'
import { ArticleCard, GridItem, TypographyV2 as Typography } from '@which/seatbelt'

import type {
  VerticalAboutWhichPageQuery,
  VerticalPensionSchemePageQuery,
} from '../../../../generated/frontend'
import styles from './VerticalMore.module.scss'

type Props = {
  data:
    | VerticalAboutWhichPageQuery['verticalPage']['aboutWhich']
    | VerticalPensionSchemePageQuery['verticalPage']['pensionScheme']
}

export const VerticalMore: FunctionComponent<Props> = ({ data }) => {
  return (
    <div data-testid="about-which-articles-more" className={styles.awMoreWrapper}>
      <GridItem span={{ medium: 12, large: 12 }} className={styles.awMoreHeading}>
        <Typography textStyle="sb-text-heading-large">{data.moreArticles.heading}</Typography>
      </GridItem>
      <div className={styles.awCardsWrapper}>
        {data.moreArticles.cards.map((element, index) => (
          <GridItem
            span={{ medium: 4, large: 4 }}
            columnStart={{
              medium: getCardColumnStart(index),
              large: getCardColumnStart(index),
            }}
            className={styles.cardsWrapper}
          >
            <ArticleCard
              title={element.title}
              image={element.image}
              href={element.url}
              date={element.updatedAt}
              className={styles.awCardArticle}
            ></ArticleCard>
          </GridItem>
        ))}
      </div>
    </div>
  )
}

///////// IMPLEMENTATION /////////

const getCardColumnStart = (index: number) => {
  if ((index + 1) % 3 === 0) {
    return 9
  } else if ((index + 1) % 2 === 0) {
    return 5
  }
  return 1
}
