import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getPensionSchemeVerticalDataLayer = (pageInfo: PageInfo) => {
  const pensionSchemeDataLayer: DataLayerInfo = {
    ...pageInfo,
    pageType: 'article-listing',
    content_type: 'pension-scheme',
  }
  delete pensionSchemeDataLayer.vertical
  delete pensionSchemeDataLayer.sub_vertical

  return pensionSchemeDataLayer
}
