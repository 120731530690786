import type { FunctionComponent } from 'react'
import React from 'react'
import type { BadgeTheme } from '@which/seatbelt'
import { Grid, GridItem, SectionHeader } from '@which/seatbelt'

import classnames from 'classnames'

import type { NewsCardsCollectionItem } from '../../../../generated/frontend'
import { FeaturedArticleCard } from '../../../../shared/components/FeaturedArticleCard'
import { CollectionLatestNews } from '../CollectionLatestNews'
import styles from './CollectionSection.module.scss'

export const CollectionSection: FunctionComponent<Props> = ({
  cards,
  taxonomy,
  featuredArticle,
}) => (
  <Grid
    data-testid="collection-section"
    className={classnames('w-page-wrapper', styles.collectionWrapper)}
  >
    <GridItem className={styles.collectionTitle}>
      <SectionHeader text={taxonomy.name} />
    </GridItem>
    <GridItem className={styles.collectionFeatured} span={{ medium: 5, large: 4 }}>
      <FeaturedArticleCard
        article={featuredArticle}
        badge={{
          theme: 'top story',
        }}
      />
    </GridItem>
    <GridItem
      span={{ medium: 7, large: 7, xlarge: 8 }}
      columnStart={{ medium: 6, large: 6, xlarge: 5 }}
    >
      <CollectionLatestNews cards={cards} taxonomy={taxonomy} />
    </GridItem>
  </Grid>
)

///////// IMPLEMENTATION /////////

type Props = NewsCardsCollectionItem
